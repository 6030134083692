<template>
  <div class="row justify-content-center h-100">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <h3>Kerala Government Hospital Development Society Employees Union</h3> 
      </div>
      <div class="section mb-5 p-2">

        <form action="index.html">
          <div class="card">
            <div class="card-body pb-1">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="name">Membership Category</label>
                  <b-form-select class="col-sm-12 category-select" v-model="membershipCategory">
                    <b-form-select-option value="null" class="text-fade">Select Membership Category</b-form-select-option>
                    <b-form-select-option value="A">A</b-form-select-option>
                    <b-form-select-option value="B">B</b-form-select-option>
                    <b-form-select-option value="C">C</b-form-select-option>
                  </b-form-select>
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.membershipCategory.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="membershipNumber">Membership Number</label>
                  <input v-model="membershipNumber" type="text" class="form-control" id="membershipNumber" placeholder="Enter Your Membership Number">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.membershipNumber.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="name">Member Name</label>
                  <input v-model="name" type="text" class="form-control" id="name" placeholder="Enter Your Name">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.name.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="email">Email</label>
                  <input v-model="email" type="email" class="form-control" id="email" placeholder="Enter Your Email">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.email.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" >Date Of Birth</label>
                  <b-datepicker v-model="dateOfBirth" :show-decade-nav="true"></b-datepicker>
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.dateOfBirth.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="label" for="phone">Phone Number</label>
                  <input v-model="phone" type="phone" disabled class="form-control" id="phone" placeholder="Phone">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger justify-content-start d-flex" v-for="error of v$.phone.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="registerMember"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Submit</span></button>
              </div>
            </div>
          </div>


        </form>
      </div>

    </div>
    <!-- * App Capsule -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, email, numeric } from '@vuelidate/validators'
import useNotyf from "../composable/useNotify";
export default {
  name: 'EnrollWithMembershipId',
  data() {
    return {
      membershipCategory: null,
      membershipNumber: '',
      name: '',
      email: '',
      dateOfBirth: '',
      phone: '',
      isWaitingResponse: false
    }
  },
  validations () {
    return {
      membershipCategory: {
        required
      },
      membershipNumber: {
        required
      },
      name: {
        required,
      },
      email: {
        required,
        email
      },
      dateOfBirth: {
        required
      },
      phone: {
        required,
        minLength: minLength(10),
        numeric
      }
    }
  },
  created() {
    this.phone = this.$route.query.phone?? ''
  },
  components: {
  },
  methods: {
    registerMember() {
      this.v$.$validate()
      if(this.v$.$error) return
      this.isWaitingResponse = true
      this.$axios.post('create-employee', {
        membership_category: this.membershipCategory,
        membership_number: this.membershipNumber,
        name: this.name,
        email: this.email,
        dob: this.dateOfBirth,
        phone_number: this.phone
      }).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 1) window.location.href = data.data.enrollment_url
        if(data.status === 0) useNotyf().error(data.message)
      }).catch(() => {
        this.isWaitingResponse = false
      })
    }
  },
  setup: () => {
    return { v$: useVuelidate() }
  }
}
</script>

<style scoped>
>>>.b-form-btn-label-control.form-control > .form-control {
  padding-top: 7px!important;
}
.category-select {
  border: 0;
  outline: none;
}

</style>
